<template>
  <div id="capture">
    <div class="capture" ref="capture">
      <h1 class="tl-c">丽日生活GO</h1>
      <h2 class="tl-c">{{ captureObj.storeName }}</h2>
      <h1 class="tl-c">* 已付款 *</h1>
      <div class="hr"></div>
      <div>订单号：{{ captureObj.orderNumber }}</div>
      <div>下单时间：{{ captureObj.createTime }}</div>
      <div>支付方式：微信</div>
      <div>订单类型：{{ getString(0, captureObj.orderType) }}</div>
      <h2>{{ captureObj.nickname }}</h2>
      <h2>{{ captureObj.phone }}</h2>
      <table class="captureTable">
        <tr>
          <th width="60%">商品名称（规格）</th>
          <th>数量</th>
          <th>单价</th>
          <th>小计</th>
        </tr>
        <tr v-for="(item, index) in captureObj.list" :key="index">
          <td>{{ item.goodsName }}</td>
          <td>{{ item.goodsNum }}</td>
          <td>{{ parseFloat(item.goodMarkPrice).toFixed(2) }}</td>
          <td>{{ (item.goodsNum * item.goodMarkPrice).toFixed(2) }}</td>
        </tr>
        <tr>
          <td colspan="4">
            总数量：{{ totalNum }}件 商品总金额：{{
              parseFloat(captureObj.actualPay).toFixed(2)
            }}
          </td>
        </tr>
      </table>
      <div>运费：0.00</div>
      <div>订单总金额：{{ parseFloat(captureObj.actualPay).toFixed(2) }}</div>
      <div class="hr"></div>
      <div>满减：0.00</div>
      <div>订单会员等级折扣：0.00</div>
      <div>积分抵扣：0.00</div>
      <div>代金券抵扣：0.00</div>
      <div>商家人工减免：0.00</div>
      <div class="hr"></div>
      <div>余额抵扣：0.00</div>
      <div>购物卡抵扣：0.00</div>
      <h2>实付金额：{{ parseFloat(captureObj.actualPay).toFixed(2) }}</h2>
      <vue-qr
        :text="qrText"
        :margin="0"
        colorDark="#000"
        colorLight="#fff"
        :size="150"
      ></vue-qr>
      <h2>{{ captureObj.qrCode }}</h2>
      <h2>买家留言：{{ captureObj.remark || "无" }}</h2>
      <h2>商家备注：无</h2>
    </div>
  </div>
</template>
<script>
import vueQr from "vue-qr";
import { smallProgram } from "@/api/print";
export default {
  components: { vueQr },
  data() {
    return {
      totalNum: 0,
      id: "",
      qrText: "",
      captureObj: {},
      order_status: [
        {
          id: 0,
          title: "未支付",
        },
        {
          id: 1,
          title: "已支付",
        },
        {
          id: 2,
          title: "已发货",
        },
        {
          id: 3,
          title: "已完成",
        },
        {
          id: 4,
          title: "退款中",
        },
        {
          id: 5,
          title: "已退款",
        },
        {
          id: 6,
          title: "已失效",
        },
        {
          id: -1,
          title: "退款失败",
        },
      ],
      order_type: [
        {
          id: 2,
          title: "积分活动",
        },
        {
          id: 1,
          title: "砍价活动",
        },
        {
          id: 6,
          title: "多人拼团",
        },
        {
          id: 7,
          title: "限时抢购",
        },
      ],
      storeName: "",
    };
  },
  methods: {
    getDetail() {
      smallProgram(id).then((res) => {
        this.captureObj = res.data.body;
        this.captureObj.storeName = this.storeName;
        this.captureObj.phone = this.captureObj.lrUserBaseInfo.phone;
        this.captureObj.nickname = this.captureObj.lrUserBaseInfo.nickname;
        this.qrText = `{ "code": ${this.captureObj.qrCode},"type":0,"title":"订单" }`;
        this.totalNum = 0;
        this.captureObj.list.map((item) => {
          this.totalNum += item.goodsNum;
        });
        setTimeout(() => {
          this.print();
        }, 1000);
      });
    },
    // 获取订单状态与类型
    getString(type, val) {
      let temp = "";
      if (type == 1) {
        this.order_status.forEach((e) => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      } else {
        this.order_type.forEach((e) => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      }
      return temp;
    },
    init() {
      this.id = this.$route.query.id;
      this.storeName = this.$route.query.storeName;
    },
    print() {
      var printData = document.getElementById("capture").innerHTML;
      window.document.body.innerHTML = printData;
      window.print();
    },
  },
  mounted() {
    this.init();
    this.getDetail();
  },
};
</script>
<style scoped>
h1 {
  font-size: 14px;
  margin: 10px 0;
}
h2 {
  font-size: 13px;
  margin: 8px 0;
}
.capture {
  font-size: 10px;
  padding: 20px;
  color: #000;
  width: 350px;
  margin: 0 auto;
}
.capture div {
  margin-bottom: 3px;
}
.captureTable {
  border-collapse: collapse;
  border-top: solid #ccc 1px;
  border-left: solid #ccc 1px;
  width: 100%;
  margin-bottom: 5px;
}
.captureTable td,
.captureTable th {
  border-right: solid #ccc 1px;
  border-bottom: solid #ccc 1px;
  text-align: center;
  height: 25px;
}
.hr {
  height: 1px;
  width: 100%;
  background: #999;
  margin-bottom: 5px;
  display: inline-block;
}
img {
  width: 80px;
  height: 80px;
}
</style>