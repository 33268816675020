import request from "@/utils/request";

// export function login(data) {
//   return request({
//     url: "/vue-admin-template/user/login",
//     method: "post",
//     data
//   });
// }

// export function getInfo() {
//   return request({
//     url: "/vue-admin-template/user/info",
//     method: "get"
//   });
// }

export function smallProgram(id) {
  return request({
    url: `/order-service/order/smallProgram/${id}`,
    method: "post",
    data
  });
}